<template>
  <div class="yimiao">
    <div>
      <!-- <div style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        ">
        <div style="display: inline-block; width: 60px">
          <img style="width: 100%"
            src="../../assets/疫苗.png"
            alt="" />
        </div>
        <div style="
            display: inline-block;
            position: absolute;
            top: 30px;
            left: 100px;
          ">
          <div style="font-size: 18px">疫苗接种排查登记</div>
        </div>
      </div> -->
      <div>
        <van-cell-group>
          <van-field v-model="datafrom.OrgName"
            required
            @click="idListOrg = true"
            readonly="readonly"
            label="社区(村)"
            placeholder="请选择社区(村)" />
          <van-popup v-model="idListOrg"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="OrganList"
              value-key="organname"
              @cancel="idListOrg = false"
              @confirm="onidListOrg">
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Region"
            required
            @click="idListRId = true"
            readonly="readonly"
            label="小区(组)"
            placeholder="请选择您所住小区(组)" />
          <van-popup v-model="idListRId"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="ridlist"
              value-key="Title"
              @cancel="idListRId = false"
              @confirm="onidListRId">
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Name"
            required
            label=" 姓 名 "
            placeholder="请输入姓名" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.IDCard"
            label="身份证号"
            required
            placeholder="请输入身份证号码" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Mobile"
            label="联系电话"
            required
            placeholder="请输入联系电话" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Address"
            required
            label="家庭住址"
            placeholder="请输入现家庭住址" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.StatusName"
            required
            @click="Status = true"
            readonly="readonly"
            label="接种疫苗情况"
            placeholder="请选择接种疫苗情况" />
          <van-popup v-model="Status"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="yimiaolist"
              value-key="Name"
              @cancel="Status = false"
              @confirm="onStatus">
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-field v-model="datafrom.VaccinateTime"
          name="VaccinateTime"
          label="接种时间"
          @click="jiezhongTime = true"
          readonly="readonly"
          placeholder="请选择接种时间" />
        <van-popup v-model="jiezhongTime"
          position="bottom"
          get-container="body">
          <van-datetime-picker v-model="jiezhognDate"
            show-toolbar
            type="date"
            title="请选择接种时间"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="jiezhongTime = false"
            @confirm="onjiezhongFend">
          </van-datetime-picker>
        </van-popup>
        <van-cell-group>
          <van-field v-model="datafrom.WorkUnit"
            label="工作单位"
            placeholder="请输入工作单位" />
        </van-cell-group>
        <!-- <van-cell-group>
          <van-field
            v-model="datafrom.ManSort"
            label="人群类别"
            placeholder="请输入人群类别"
          />
        </van-cell-group> -->
        <van-cell-group>
          <van-field v-model="datafrom.Remark"
            label="备注"
            placeholder="请输入备注" />
        </van-cell-group>
      </div>
      <!-- <div class="buttons">
        <van-button type="info" @click="postfrom()">提交</van-button>
      </div> -->
      <div class="buttons">
        <van-button type="info"
          style="margin: 10px 0"
          @click="postfrom()">提交</van-button>
      </div>
      <!-- <router-link to="/register">
        <div class="nameResume">返回</div>
      </router-link> -->
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
Vue.use(Toast);
import {
  WeGetRegionList,
  WeGetBuildList,
  WeGetBuildUnitList,
  WeGetBuildFloorList,
  WeGetBuildRoomList,
  GetCodeEntrysList,
  WxSaveCheck,
  GetOrgansNullToken,
} from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      minDate: new Date(2015, 0, 1),
      maxDate: new Date(2030, 10, 1),
      ridlist: [],
      BIdlist: [],
      BUIdlist: [],
      BFIdlist: [],
      BRIdlist: [],
      diqu: [],
      guanxi: [],
      minzu: [],
      xveli: [],
      zhuangtai: [],
      hunyin: [],
      idListOrg: false,
      NativeCantonCodedata: [],
      sexlist: [
        { id: "1", Name: "男" },
        { id: "2", Name: "女" },
      ],
      yimiaolist: [
        { id: "1", Name: "未接种" },
        { id: "2", Name: "第一针" },
        { id: "3", Name: "第二针" },
        { id: "4", Name: "第三针" },
      ],
      idList: {
        organCode: "4103110201",
        RId: undefined,
      },
      datafrom: {
        ID: 0, //排查id、
        Name: "", //姓名、
        IDCard: "", //身份证、
        Mobile: "", //联系方式、
        Address: "", //家庭住址、
        Status: "", //接种状态（1未接种、2第一针、3第二针）、
        Remark: "", //备注、
        OpenID: "", //群众openid
        OrgCode: "",
        OrgName: "",
        Region: "",
        RId: "",
        WorkUnit: "",
        ManSort: "",
        VaccinateTime: "",
      },
      jiezhognDate: "",
      idListRId: false,
      addshow: false,
      verifyshow: true,
      sexshow: false,
      Nationshow: false,
      Status: false,
      jiezhongTime: false, //接种时间
    };
  },
  created () {
    // this.RegionList();
    this.GetOrgansNullToken();
  },
  methods: {
    onjiezhongFend (val) {
      //接种时间 确认
      this.datafrom.VaccinateTime = this.dateformat(val);
      this.jiezhongTime = false;
    },
    // 社区列表
    GetOrgansNullToken: function (row) {
      GetOrgansNullToken({ level: 4, kind: 1 }).then((res) => {
        this.OrganList = res.data.data;
      });
      this.datafrom.Name = this.$route.params.name;
      if (this.$route.params.idcard == "undefined") {
        this.datafrom.IDCard = '';
      } else {
        this.datafrom.IDCard = this.$route.params.idcard;
      }
    },
    // 社区选择器
    onidListOrg (val) {
      this.datafrom.OrgCode = val.organcode;
      this.datafrom.OrgName = val.organname;
      this.idListOrg = false;
      this.RegionList(val.organcode);
      this.datafrom.Region = "";
      // this.BuildList(val.RId);
    },
    // 小区(组)选择器
    onidListRId (val) {
      this.datafrom.RId = val.RId;
      this.datafrom.Region = val.Title;
      this.idListRId = false;
    },
    dateformat (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
    // 提交
    postfrom: function () {
      //身份证号验证
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      // 手机号验证
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (this.datafrom.OrgName == "") {
        Toast.fail("请选择社区(村)");
        return false;
      }
      if (this.datafrom.Region == "") {
        Toast.fail("请选择小区(组)");
        return false;
      }
      if (this.datafrom.Name == "") {
        Toast.fail("请输入姓名");
        return false;
      }
      if (this.datafrom.IDCard == "") {
        Toast.fail("请输入身份证号码");
        return false;
      } else {
        if (!reg.test(this.datafrom.IDCard)) {
          Toast.fail("身份证号码不正确");
          return false;
        }
      }
      if (this.datafrom.Mobile == "") {
        Toast.fail("请输入联系电话");
        return false;
      } else {
        if (!reg_tel.test(this.datafrom.Mobile)) {
          Toast.fail("请输入正确的联系电话");
          return false;
        }
      }
      if (this.datafrom.Address == "") {
        Toast.fail("请输入家庭住址");
        return false;
      }
      if (this.datafrom.StatusName == "") {
        Toast.fail("请选择疫苗接种情况");
        return false;
      }
      this.datafrom.OpenID = getOpenId();
      // this.datafrom.OpenID = 9465456165156;
      WxSaveCheck(this.datafrom).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            // this.$router.push({
            //   name: "register",
            // });
            this.$router.go(-1)
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
    // 小区(组)列表
    RegionList: function (row) {
      WeGetRegionList({ organCode: row }).then((res) => {
        this.ridlist = res.data.data;
      });
    },
    // 房屋列表
    BuildRoomList: function (val) {
      WeGetBuildRoomList({
        bId: this.idList.BId,
        buId: this.idList.buId,
        bfId: val,
      }).then((res) => {
        console.log(res);
        this.BRIdlist = res.data.data;
      });
    },
    // 性别选择器
    onsexshow (val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
      this.sexshow = false;
    },
    // 疫苗选择器
    onStatus (val) {
      this.datafrom.Status = val.id;
      this.datafrom.StatusName = val.Name;
      this.Status = false;
    },
  },
};
</script>
<style>
.yimiao .inputFrom {
  margin: 15px 8%;
}
.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.yimiao .buttons {
  text-align: center;
}
.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}
.nameResume {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background: #0188fb;
  padding: 8px;
  width: 50px;
  height: 50px;
  font-size: 12px;
  line-height: 50px;
  border-radius: 15px;
  color: #fff;
  text-align: center;
}
</style>